<template>
  <el-dialog
    title="上傳圖片"
    :visible="true"
    width="40%"
    :append-to-body="appendBody"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <!-- <input
      v-if="dics"
      ref="fileInput"
      type="file"
      name=""
      id=""
      @change="loadImg"
    /> -->
    <div class="cropper-wrapper" data-cy="img-cropper">
      <cropper
        ref="cropper"
        class="cropper"
        image-restriction="none"
        :src="image.blob"
        :canvas="{
          maxWidth: 2000,
        }"
        :stencil-props="{
          aspectRatio: ratio[0] / ratio[1],
        }"
        :default-size="
          defaultImgSize
        "
        :debounce="false"
        background-class="cropper__background"
        @change="resize"
      />
    </div>

    <preview
      v-if="result.image && preview"
      class="preview"
      :width="150"
      :height="cropDimension()"
      :image="result.image"
      :coordinates="result.coordinates"
    />
    <BaseElButton
      v-loading="uploading"
      data-cy="upload-img-btn"
      plain
      class="w-full"
      style="margin-top: 20px"
      @click="upload"
    >
      上傳
    </BaseElButton>
    <div class="notice">{{ notice }}</div>
  </el-dialog>
</template>

<script>
import { Cropper, Preview } from 'vue-advanced-cropper'
import { admin } from '@/api/instance'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'ImageCropper',
  components: { Cropper, Preview },
  // props: ['image', 'dics', 'appendBody', 'ratio'],
  props: {
    image: Object,
    dics: Object,
    appendBody: Object,
    ratio: {
      type: Array,
      default: () => [10, 10],
    },
    preview: {
      type: Boolean,
      default: true,
    },
    notice: String,
  },
  data: () => ({
    img: null,
    result: {
      coordinates: null,
      image: null,
    },
    uploading: false,
    fileType: {
      jpg: 'jpeg',
      png: 'png',
      jpeg: 'jpeg',
    },
  }),
  computed: {
    sortRatio () {
      const r = [...this.ratio]
      return r.sort((a, b) => b - a)
    },

  },
  methods: {
    cropDimension () {
      const target = document.getElementsByClassName('vue-preview__wrapper')
      if (target[0]) {
        const w = target[0].style?.width.replace('px', '')
        const h = target[0].style?.height.replace('px', '')
        return Number(h) * (150 / Number(w))
      }
      return 150
    },
    defaultImgSize ({ visibleArea, imageSize, stencilRatio, sizeRestrictions }) {
      return {
        width: imageSize.width,
        height: imageSize.height,
      }
    },
    resize ({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },
    loadImg (e) {
      const input = e.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.img = e.target.result
          this.$refs.fileInput.type = 'text'
          this.$refs.fileInput.type = 'file'
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },

    //= > 關閉對話框
    closeDialog () {
      this.result.coordinates = null
      this.result.image = null

      this.$emit('close')
    },

    //= > 上傳圖片到伺服器
    async upload () {
      this.uploading = true

      // const fileType = this.fileType[this.image.name.split('.')[1]]
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const form = new FormData()
        const imgDataUrl = canvas.toDataURL()

        canvas.toBlob(async (blob) => {
          // console.log(blob.size)
          if (blob.size > 8000000) {
            this.$message.warning('檔案大小超過 8mb !')
            return
          }
          console.log(blob.size, blob.type)
          let name = this.image.name.split('.')
          name.pop()
          name.join('')
          name = name + '.png'
          form.append('file', blob, name.toLowerCase())
          try {
            const res = await admin({
              method: 'post',
              url: 'image/upload',
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${this.$store.getters.token}`,
              },
              data: form,
            })
            this.$emit('uploaded', res.data)
            this.$emit('done', { res: res.data, imgDataUrl })
            this.uploading = false
          } catch (error) {
            console.log(error)
            this.$message.error({
              message: error || error.message,
            })
            this.uploading = false
          }
        }, 'image/png')
        // `image/${fileType}`
      }
    },
  },
}
</script>

<style lang="scss"></style>

<style scoped lang="scss">
.cropper {
  @apply max-h-[300px];
  background: transparent;
  &__background {
    background: transparent;
  }
}
.cropper-wrapper {
  // @apply max-h-[300px];
}
.preview {
  @apply border;
  margin: 15px auto;
}
.notice {
  text-align: right;
  font-size: 13px;
  color: #d8749c;
}
</style>
