<template>
  <div v-loading="!loaded" class="serial-activity-edit">
    <PageTitle
      :title="pageTitle"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'RewardActivityList' })"
    />

    <div class="card-container form-wrapper">
      <p class="card-title">基本資訊</p>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="圖片" prop="image" class="form-relative-label others">
          <UploadButton
            cyUploadBtn="ticket-img-upload-btn"
            cyUploadedImg="ticket-img-uploaded"
            :img="formData.image"
            width="275px"
            height="133px"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem label="活動名稱" prop="name">
          <BaseElInput v-model="formData.name" placeholder="請輸入" />
        </BaseElFormItem>
        <BaseElFormItem label="活動期間" prop="dateTime">
          <el-date-picker
            v-model="formData.dateTime"
            editable
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            range-separator="至"
            start-placeholder="開始時間日期"
            end-placeholder="結束時間日期"
          />
        </BaseElFormItem>
        <BaseElFormItem label="活動描述" prop="description">
          <quillEditor ref="quill" v-model="formData.description" :options="editorOption" />
        </BaseElFormItem>
      </BaseElForm>
    </div>
    <ImageCropper
      v-if="modal.imgCcropper"
      :image="formData.img"
      :ratio="[275,133]"
      @uploaded="getImage"
      @close="modal.imgCcropper = false"
    />
    <BaseDialog
      v-if="modal.createSuccessHint"
      title="活動已建立"
      @close="modal.createSuccessHint = false"
      @cancel="$router.push({name: 'RewardActivityList'})"
      @confirm="$router.push({name: 'SerialCodeManagement', params: {id: formData.id} })"
    >
      <div style="margin-bottom: 30px">
        活動已建立，請確認是否前往序號管理進行設定。
      </div>
    </BaseDialog>
    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({name: 'RewardActivityList'})"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, reactive, ref } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { useEditor } from '@/use/editor'
import PageTitle from '@/components/Title/PageTitle.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { CreateSerialActivity, UpdateSerialActivity, FindSerialActivity } from '@/api/serialActivity'
import { useRoute, useRouter } from 'vue-router/composables'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'SerialActivityEdit',
  components: { quillEditor, PageFixedFooter, UploadButton, ImageCropper, PageTitle, BaseDialog },
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const { editorOption } = useEditor()
    const { shopId } = useShop()
    const { formData, formRef, initFormData, onSubmit, loading } = useBaseForm()
    initFormData({
      name: null,
      dateTime: [],
      description: null,
      image: null,
      img: null,
      id: null,
    })
    const formRules = {
      name: [noEmptyRules()],
      dateTime: [noEmptyRules()],
      description: [noEmptyRules()],
      image: [noEmptyRules()],
    }
    const loaded = ref(false)
    const pageTitle = computed(() => {
      const base = '序號登錄活動'
      return activityId.value ? `編輯${base}` : `新增${base}`
    })
    const activityId = computed(() => route.params.id)
    const mode = computed(() => activityId.value ? 'edit' : 'create')
    const compactFormData = computed(() => {
      const { name, dateTime, description, image } = formData
      return {
        shopId: shopId.value,
        id: mode.value === 'edit' ? activityId.value : undefined,
        name,
        startAt: dateTime[0],
        endAt: dateTime[1],
        description,
        imageId: get(image, 'id'),
      }
    })

    const modal = reactive({
      imgCcropper: false,
      createSuccessHint: false,
    })

    const findActivity = async () => {
      const [res, err] = await FindSerialActivity({
        shopId: shopId.value,
        id: activityId.value,
      })
      if (err) window.$message.error(err)
      return res
    }
    const syncData = (data) => {
      formData.name = data.name
      formData.dateTime = [data.startAt, data.endAt]
      formData.description = data.description
      formData.image = data.Image || null
    }

    const handleSubmit = async (data, { emit }) => {
      let apiMethod
      if (mode.value === 'edit') apiMethod = UpdateSerialActivity
      if (mode.value === 'create') apiMethod = CreateSerialActivity

      const [res, err] = await apiMethod(data)
      if (err) {
        window.$message.error(err)
        return
      }
      let msg
      if (mode.value === 'edit') msg = '更新成功！'
      if (mode.value === 'create') msg = '新增成功！'
      formData.id = res.id
      window.$message.success(msg)
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, compactFormData.value, { emit })
      if (mode.value === 'create') modal.createSuccessHint = true
      if (mode.value === 'edit') router.push({ name: 'RewardActivityList' })
    }

    const loadImg = (img) => {
      formData.img = img
      modal.imgCcropper = true
    }

    const getImage = (data) => {
      formData.image = data
      // avatarChanged.value = true
      modal.imgCcropper = false
    }

    onMounted(async () => {
      if (activityId.value) {
        const res = await findActivity()
        if (res) syncData(res)
      }
      loaded.value = true
    })
    return { pageTitle, editorOption, formRules, formData, formRef, onConfirm, loaded, loading, modal, loadImg, getImage }
  },
})
</script>

<style lang="postcss" scoped>
.form-wrapper {
  @apply px-[24px] py-[20px];
}
</style>
